<template>
  <div class="app__page page_404">
    <div class="page_404__container">
      <div class="page_404__num">404</div>
      <h1 class="page_404__title">
        {{ $t("page_404.title") }}
      </h1>

      <localized-link to="/flats" class="page_404__btn btn">
        {{ $t("page_404.btn") }}
      </localized-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "page_404",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.$emitter.emit("hide-preloader");
    });
  }
};
</script>
<style scoped lang="less">
.page_404 {
  .padding-page;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 700px;
  overflow: hidden;
  @media @bw1170 {
    justify-content: flex-end;
  }
  @media @bw400 {
    padding-bottom: 50px;
  }
  &__num {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 1490px;
    width: 100%;
    text-align: center;
    font-family: @font2;
    font-weight: 700;
    font-size: 1000px;
    //font-size: 52vw;
    height: 0.82em;
    color: transparent;
    -webkit-text-stroke: 1px @white_20;
    text-stroke: 1px @white_20;
    letter-spacing: 0.15em;
    z-index: -1;
    pointer-events: none;
    @media @bw1510 {
      max-width: 894px;
      font-size: 600px;
    }
    @media @w1510bh750 {
      max-width: 894px;
      font-size: 600px;
    }
    @media @bw1170 {
      position: static;
      max-width: 768px;
      font-size: 515px;
      height: 1em;
    }
    @media @bw768 {
      margin-bottom: 85px;
      max-width: 358px;
      font-size: 240px;
    }
    @media @bw400 {
      font-size: 176px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    margin: 0 0 40px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
    @media @bw1510 {
      font-size: 24px;
    }
    @media @w1510bh750 {
      font-size: 24px;
    }
    @media @bw400 {
      margin: 0 0 30px;
      font-size: 20px;
    }
  }
  &__btn {
    margin: 0 20px;
    max-width: 300px;
    @media @bw600 {
      max-width: calc(100% - 40px);
    }
  }
}
</style>
